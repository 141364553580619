import * as React from "react"
import {css} from "@emotion/core"

/**
 * @typedef {object} IconButtonOwnProps
 * @property {string=} icon
 * @property {number} size
 * @property {number=} hitRect
 *
 * @typedef {React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>} RawButtonProps
 * @typedef {RawButtonProps & IconButtonOwnProps} IconButtonProps
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLImageElement, IconButtonProps>}
 */
const IconButtonForwardRefRender = (
  {icon = "btn-upload", size = 20, hitRect = 0, ...props},
  ref,
) => {
  return (
    <button
      css={css`
        background-image: url(${require(`../../images/${icon}.svg`)});
        background-size: ${size}px ${size}px;
        background-repeat: no-repeat;
        background-position: center;
        width: ${size + hitRect}px;
        height: ${size + hitRect}px;

        .component-base-icon-button-parent:hover &,
        &:hover {
          background-image: url(${require(`../../images/${icon}-hover.svg`)});
        }
        .component-base-icon-button-parent:active &,
        &:active,
        &.active {
          background-image: url(${require(`../../images/${icon}-pressed.svg`)});
        }
      `}
      ref={ref}
      {...props}
    />
  )
}

/**
 * @type {React.ForwardRefExoticComponent<IconButtonProps>}
 */
export const IconButton = React.forwardRef(IconButtonForwardRefRender)
